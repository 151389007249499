import React from 'react';
import '../../index.css'

const mainFooter = () => (
    <div className="footer">
        <div className="icon">
            <a href="https://cvtrends.com/">
                <img
                    src='https://cvtrends.com/wp-content/uploads/2018/08/cvt_logo_white.png'
                    alt="cvtrend-logo" />
            </a>
            <p className="copyright">Todos los derechos reservados.</p>
        </div>
        <div className="linkFooter">
            <a className="linkSingleFooter" href="https://cvtrends.com/politica-de-cookies">POLÍTICA DE COOKIES</a>
            <a className="linkSingleFooter" href="https://cvtrends.com/aviso-legal">AVISO LEGAL</a>
            <a className="linkSingleFooter" href="https://cvtrends.com/politica-privacidad">POLÍTICA DE PRIVACIDAD</a>
            <a className="linkSingleFooter" href="https://cvtrends.com/quienes-somos">QUIÉNES SOMOS</a>
        </div>
    </div>
);

export default mainFooter;