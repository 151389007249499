import React, { Component } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    section: {
        marginTop: 20
    },
    title: {
        textTransform: 'uppercase',
        fontSize: 15,
        fontWeight: 700
    },
    container: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end'
    },
    text: {
        fontSize: 12
    },
    date: {
        fontSize: 10,
        fontStyle: 'italic'
    },
    firstItemFlex: {
        width: '40%'
    },
    secondItemFlex: {
        width: '60%'
    },
    titleExp: {
        width: '60%',
        fontWeight: 700
    },
    divider: {
        position: 'absolute',
        backgroundColor: '#c8c8c8',
        width: 2,
        height: '100%',
        marginLeft: 150
    },
    building: {
        color: 'gray'
    },
    description: {
        textAlign: 'justify'
    }
});

const getFormatDate = form_date => {
    if (form_date) {
        const date = new Date(form_date);
        const month = date.getMonth() + 1;
        return (date.getDate() + " - " + month + " - " + date.getFullYear());
    }
}

class Studies extends Component {
    render() {
        const a_state = this.props.handleDataState;
        const num_of_items = a_state['num_of_studies'];
        let items = [];

        if (typeof num_of_items === "undefined") {
            items.push(
                <View key={"single_study_key"} style={[styles.container, styles.text]}>
                    <Text style={[styles.firstItemFlex, styles.date]}>{'01 - 12 - 2008'} | {'04 - 05 - 2009'}</Text>
                    <Text style={[styles.titleExp]}>{'Nombre del Master'}</Text>
                    <Text style={[styles.building, styles.secondItemFlex]}>{'Universidad'} - {'Malaga'}</Text>
                    <Text style={[styles.description, styles.secondItemFlex]}>{'Descripción '}</Text>
                </View>
            );
            items.push(
                <View key={"doble_study_key"} style={[styles.container, styles.text]}>
                    <Text style={[styles.firstItemFlex, styles.date]}>{'01 - 09 - 2001'} | {'04 - 05 - 2004'}</Text>
                    <Text style={[styles.titleExp]}>{'Facultad de Comunicación Digital'}</Text>
                    <Text style={[styles.building, styles.secondItemFlex]}>{'Universidad'} - {'Sevilla'}</Text>
                    <Text style={[styles.description, styles.secondItemFlex]}>{'Descripción '}</Text>
                </View>
            );
        }
        else {
            items = [];
            for (let i = 1; i <= num_of_items; i++) {
                items.push(
                    <View key={"single_study_" + i} style={[styles.container, styles.text]}>
                        <Text style={[styles.firstItemFlex, styles.date]}>{getFormatDate(a_state['st_date_start_' + i])} | {getFormatDate(a_state['st_date_end_' + i]) || 'actual'}</Text>
                        <Text style={[styles.titleExp]}>{a_state['st_career_' + i]}</Text>
                        <Text style={[styles.building, styles.secondItemFlex]}>{a_state['st_college_' + i]} - {a_state['st_city_' + i]}</Text>
                        <Text style={[styles.description, styles.secondItemFlex]}>{a_state['st_description_' + i]}</Text>
                    </View>
                );
            }
        }
        return (
            <View wrap style={styles.section}>
                <Text style={[styles.divider, { backgroundColor: a_state['background'] }]}></Text>
                <Text style={styles.title}>{'estudios'}</Text>
                {items}
            </View>
        )
    }
}

export default Studies;