/* eslint-disable import/first */
import React, { Component } from 'react';
import { Form, Button, Accordion, Card } from 'react-bootstrap';
import { Row, Col, ButtonToolbar, OverlayTrigger, Popover, Toast } from 'react-bootstrap';
import text from '../../text.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es);

import "react-datepicker/dist/react-datepicker.css";

const experience = text.experiencias;

// Create Document Component
class ExperienceDataForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            num_of_experiences: 0,
            validated: false,
            showToast: false
        }

        this.handleHeaderChange = this.handleHeaderChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    handleHeaderChange(value, property) {
        this.setState({ [property]: value },
            () => {
                //async sending state
                //this.props.handleExperienceData(this.state);
            })
    }

    handleSubmit(event) {
        //Validation
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        this.setState({ validated: true });
        if (form.checkValidity()) {
            this.props.handleExperienceData(this.state);
            this.setState({
                showToast: true
            }, () => {
                setTimeout(() =>
                    this.setState({ showToast: false })
                    , 2000)
            })
        }
        event.preventDefault();
    }

    addItem(property) {
        this.setState({ [property]: this.state[property] + 1 },
            () => { });

    }

    removeItem(index, a_items, a_card, property) {
        this.setState({ [property]: this.state[property] - 1 },
            () => {
                a_items.forEach(element => {
                    this.setState({
                        [element + index]: this.state[element + (index + 1)],
                        [element + (index + 1)]: ''
                    });
                });
                a_card.splice((index - 1), 1);
            });
    }

    showExperience(num) {
        let cardExperience = [];
        const itemDesc = ['date_start_', 'date_end_', 'exp_profile_', 'exp_company_', 'exp_description_', 'skill_first_', 'skill_second_', 'skill_third_', 'skill_fourth_'];

        for (let i = 1; i <= num; i++) {
            cardExperience.push(
                <Accordion key={i + "_exp"} defaultActiveKey={1}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant='link' eventKey={i}>Editar</Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={i}>
                            <Card.Body>
                                <Form.Group>
                                    <Row>
                                        <Col md={12} lg={6}>
                                            <Form.Label>{experience.card_date_start}</Form.Label>
                                            {/*<Form.Control required value={this.state['date_start_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('date_start_' + i).toString())} type="date" />*/}
                                            <DatePicker
                                                selected={this.state['date_start_' + i] || ''}
                                                onChange={(event) => this.handleHeaderChange(event, ('date_start_' + i).toString())}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={<Form.Control />}
                                                placeholderText="dd/mm/aaaa"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                withPortal
                                                isClearable={true}
                                                maxDate={new Date()}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">{experience.card_val_date_start}</Form.Control.Feedback>
                                        </Col>
                                        <Col md={12} lg={6}>
                                            <Form.Label>{experience.card_date_end}</Form.Label>
                                            {/*<Form.Control value={this.state['date_end_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('date_end_' + i).toString())} type="date" />*/}
                                            <DatePicker
                                                selected={this.state['date_end_' + i] || ''}
                                                onChange={(event) => this.handleHeaderChange(event, ('date_end_' + i).toString())}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={<Form.Control />}
                                                placeholderText="dd/mm/aaaa"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                withPortal
                                                maxDate={new Date()}
                                                isClearable={true}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row>
                                        <Col>
                                            <Form.Control required value={this.state['exp_profile_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('exp_profile_' + i).toString())} placeholder={experience.card_ph_job} />
                                            <Form.Control.Feedback type="invalid">{experience.card_val_job}</Form.Control.Feedback>
                                        </Col>
                                        <Col>
                                            <Form.Control required value={this.state['exp_company_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('exp_company_' + i).toString())} placeholder={experience.card_ph_company} />
                                            <Form.Control.Feedback type="invalid">{experience.card_val_company}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control required value={this.state['exp_description_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('exp_description_' + i).toString())} as="textarea" rows="3" placeholder={experience.card_ph_desc} />
                                    <Form.Control.Feedback type="invalid">{experience.card_val_desc}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Row>
                                        <Col>
                                            <Form.Control value={this.state['skill_first_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('skill_first_' + i).toString())} type="text" placeholder={experience.card_ph_skill} />
                                        </Col>
                                        <Col>
                                            <Form.Control value={this.state['skill_second_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('skill_second_' + i).toString())} type="text" placeholder={experience.card_ph_skill} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Control value={this.state['skill_third_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('skill_third_' + i).toString())} type="text" placeholder={experience.card_ph_skill} />
                                        </Col>
                                        <Col>
                                            <Form.Control value={this.state['skill_fourth_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('skill_fourth_' + i).toString())} type="text" placeholder={experience.card_ph_skill} />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Toast show={this.state.showToast}><Toast.Body>¡Tus datos se han guardado correctamente!</Toast.Body></Toast>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Button className="btn_remove" variant="link" onClick={() => this.removeItem(i, itemDesc, cardExperience, 'num_of_experiences')}>{experience.card_btn_remove}</Button>
                    </Card>
                </Accordion>
            );
        }
        return cardExperience;
    }

    render() {
        return (
            <Card id="scroll_study">
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1" id="experience"
                                onClick={() => document.getElementById("scroll_experience").scrollIntoView(true)}>
                                {experience.title}
                            </Accordion.Toggle>
                        </Col>
                        <Col xs={{ span: 2, offset: 4 }}>
                            <ButtonToolbar className="btn_info">
                                <OverlayTrigger
                                    trigger="click"
                                    key="left"
                                    placement="left"
                                    overlay={
                                        <Popover
                                            id={`popover-positioned-left`}
                                        >
                                            {experience.info}
                                        </Popover>
                                    }
                                >
                                    <FontAwesomeIcon icon={faInfo} className="ico_info" />
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Accordion defaultActiveKey="0">
                                {this.showExperience(this.state.num_of_experiences)}
                                <Button className="btn_add_more" variant="secondary" onClick={() => this.addItem('num_of_experiences')}>{experience.btn_add}</Button>
                            </Accordion>
                            <Button style={{ margin: '20px auto', width: '100%' }} variant="warning" type="submit" /*onClick={this.handleSubmit}*/>
                                {experience.btn_update}
                            </Button>
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }

}



export default ExperienceDataForm;