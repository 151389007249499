import React, { Component } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    section: {
        marginTop: 20
    },
    title: {
        textTransform: 'uppercase',
        fontSize: 15,
        fontWeight: 700
    },
    container: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end'
    },
    text: {
        fontSize: 12
    },
    date: {
        fontSize: 10,
        fontStyle: 'italic'
    },
    img: {
        width: 5,
        height: 5
    },
    firstItemFlex: {
        width: '40%'
    },
    secondItemFlex: {
        width: '60%'
    },
    titleExp: {
        width: '60%',
        fontWeight: 700
    },
    divider: {
        position: 'absolute',
        backgroundColor: '#c8c8c8',
        width: 2,
        height: '100%',
        marginLeft: 150
    },
    company: {
        color: 'gray'
    },
    description: {
        textAlign: 'justify'
    }
});


const getFormatDate = form_date => {
    if (form_date) {
        const date = new Date(form_date);
        const month = date.getMonth() + 1;
        return (date.getDate() + " - " + month + " - " + date.getFullYear());
    }
}

class Experience extends Component {
    render() {
        const a_state = this.props.handleDataState;
        const num_of_items = a_state['num_of_experiences'];
        let items = [];

        if (typeof num_of_items === "undefined") {
            items.push(
                <View key={"keyZero"} style={[styles.container, styles.text]}>
                    <Text style={[styles.firstItemFlex, styles.date]}>{'08 - 09 - 2009'} | {'11 - 12 - 2010'}</Text>
                    <Text style={[styles.titleExp]}>{'Trabajo 1'}</Text>
                    <Text style={[styles.firstItemFlex]}></Text>
                    <Text style={[styles.company, styles.secondItemFlex]}>{'Empresa 1'}</Text>
                    <Text style={[styles.firstItemFlex]}></Text>
                    <Text style={[styles.description, styles.secondItemFlex]}>{'   '}</Text>
                    <Text style={[styles.firstItemFlex]}></Text>
                    <Text style={[styles.secondItemFlex]}>{' • Responsable del departamento de Diseño Web.'}</Text>
                    <Text style={[styles.firstItemFlex]}></Text>
                    <Text style={[styles.secondItemFlex]}>{' • Orientación a cliente.'}</Text>
                    <Text style={[styles.firstItemFlex]}></Text>
                    <Text style={[styles.secondItemFlex]}>{' • Diseño UI/UX'}</Text>                    
                </View>
            );
            items.push(
                <View key={"keyOne"} style={[styles.container, styles.text]}>
                    <Text style={[styles.firstItemFlex, styles.date]}>{'15 - 11 - 2008'} | {'11 - 01 - 2009'}</Text>
                    <Text style={[styles.titleExp]}>{'Trabajo 2'}</Text>
                    <Text style={[styles.firstItemFlex]}></Text>
                    <Text style={[styles.company, styles.secondItemFlex]}>{'Empresa 2'}</Text>
                    <Text style={[styles.firstItemFlex]}></Text>
                    <Text style={[styles.description, styles.secondItemFlex]}>{'   '}</Text>
                    <Text style={[styles.firstItemFlex]}></Text>
                    <Text style={[styles.secondItemFlex]}>{' • Diseño web para dos proyectos multinacionales.'}</Text>
                    <Text style={[styles.firstItemFlex]}></Text>
                    <Text style={[styles.secondItemFlex]}>{' • Contacto con el Cliente.'}</Text>                
                </View>
            );
        }
        else {
            items = [];
            for (let i = 1; i <= num_of_items; i++) {                
                items.push(
                    <View key={"exp_key" + i} style={[styles.container, styles.text]}>
                        <Text style={[styles.firstItemFlex, styles.date]}>{getFormatDate(a_state['date_start_' + i])} | {getFormatDate(a_state['date_end_' + i]) || 'actual'}</Text>
                        <Text style={[styles.titleExp]}>{a_state['exp_profile_' + i]}</Text>
                        <Text style={[styles.firstItemFlex]}></Text>
                        <Text style={[styles.company, styles.secondItemFlex]}>{a_state['exp_company_' + i]}</Text>
                        <Text style={[styles.firstItemFlex]}></Text>
                        <Text style={[styles.description, styles.secondItemFlex]}>{a_state['exp_description_' + i]}</Text>
                        <Text style={[styles.firstItemFlex]}></Text>
                        <Text style={[styles.secondItemFlex]}>
                            {(typeof a_state['skill_first_' + i] !== "undefined") ? " • " + a_state['skill_first_' + i] : ""}
                        </Text>
                        <Text style={[styles.firstItemFlex]}></Text>
                        <Text style={[styles.secondItemFlex]}>
                            {(typeof a_state['skill_second_' + i] !== "undefined") ? " • " + a_state['skill_second_' + i] : ""}
                        </Text>
                        <Text style={[styles.firstItemFlex]}></Text>
                        <Text style={[styles.secondItemFlex]}>
                            {(typeof a_state['skill_third_' + i] !== "undefined") ? " • " + a_state['skill_third_' + i] : ""}
                        </Text>
                        <Text style={[styles.firstItemFlex]}></Text>
                        <Text style={[styles.secondItemFlex]}>
                            {(typeof a_state['skill_fourth_' + i] !== "undefined") ? " • " + a_state['skill_fourth_' + i] : ""}
                        </Text>
                    </View>
                );
            }

        }
        return (
            <View wrap style={styles.section}>
                <Text style={[styles.divider, { backgroundColor: a_state['background'] }]}></Text>
                <Text style={styles.title}>{'experiencias'}</Text>
                {items}
            </View>
        )
    }
}

export default Experience;