import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import PersonalDataForm from './components/form/PersonalDataForm';
import ExperienceDataForm from './components/form/ExperienceDataForm';
import StudyDataForm from './components/form/StudyDataForm';
import LanguageDataForm from './components/form/LanguageDataForm';
import InfoDataForm from './components/form/InfoDataForm';

// Create Document Component
class FormPDF extends Component {


    handleFromControl = (formModel) => {
        this.setState({ ...formModel }, () => {
            this.props.handleData(this.state);
        });
    }

    render() {
        return (
            <Accordion defaultActiveKey="0">
                <PersonalDataForm handlePersonalData={this.handleFromControl} />
                <ExperienceDataForm handleExperienceData={this.handleFromControl} />
                <StudyDataForm handleStudyData={this.handleFromControl} />
                <LanguageDataForm handleLanguageData={this.handleFromControl} />
                <InfoDataForm handleInfoData={this.handleFromControl} />
            </Accordion>
        )
    }
}

export default FormPDF;