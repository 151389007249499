import React, { Component } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    section: {
        marginTop: 20
    },
    title: {
        textTransform: 'uppercase',
        fontSize: 15,
        fontWeight: 700
    },
    container: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end'
    },
    text: {
        fontSize: 12
    },
    language: {
        fontSize: 10,
        fontStyle: 'italic'
    },
    firstItemFlex: {
        width: '40%'
    },
    secondItemFlex: {
        width: '60%'
    },
    divider: {
        position: 'absolute',
        backgroundColor: '#c8c8c8',
        width: 2,
        height: '100%',
        marginLeft: 150
    }
});

class Languages extends Component {
    render() {
        const a_state = this.props.handleDataState;
        const num_of_items = a_state['num_of_languages'];
        let items = [];

        if (typeof num_of_items === "undefined") {
            items.push(
                <View key={"key_single_language"} style={[styles.container, styles.text]}>
                    <Text style={[styles.firstItemFlex, styles.language]}>{'Español'}</Text>
                    <Text style={[styles.secondItemFlex]}>{'Nativo'}</Text>
                </View>
            );
            items.push(
                <View key={"key_doble_language"} style={[styles.container, styles.text]}>
                    <Text style={[styles.firstItemFlex, styles.language]}>{'Inglés'}</Text>
                    <Text style={[styles.secondItemFlex]}>{'Autonomo'}</Text>
                </View>
            );
        }
        else {
            items = [];
            for (let i = 1; i <= num_of_items; i++) {
                items.push(
                    <View key={"single_language_" + i} style={[styles.container, styles.text]}>
                        <Text style={[styles.firstItemFlex, styles.language]}>{a_state['lan_nation_' + i]}</Text>
                        <Text style={[styles.secondItemFlex]}>{a_state['lan_level_' + i]}</Text>
                    </View>
                );
            }
        }
        return (
            <View wrap style={styles.section}>
                <Text style={[styles.divider, { backgroundColor: a_state['background'] }]}></Text>
                <Text style={styles.title}>{'idiomas'}</Text>
                {items}
            </View>
        )
    }
}

export default Languages;