import React, { Component } from 'react';
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import marker from '../../assets/img/marker.png';
import mobile from '../../assets/img/mobile.png';
import email from '../../assets/img/email.png';
//import avatar from '../../assets/img/avatar.png';

// Create styles
const styles = StyleSheet.create({
    cover: {
        marginTop: -60,
        marginLeft: -60,
        width: 600
    },
    section: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 30,
        marginLeft: 30
    },
    image: {
        width: 100,
        height: 100,
        borderRadius: 50
    },
    personal: {
        marginLeft: 20,
        width: '70%'
    },
    name: {
        marginTop: 10,
        fontSize: 35,
        fontWeight: 700 
    },
    description: {
        textAlign: 'justify',
        marginTop: 140,
        fontSize: 12,
        padding: '20 40'
    },
    job: {
        fontSize: 15,
        fontStyle: 'italic'
    },
    contact: {
        marginTop: 10,
        fontSize: 12,
    },
    imageContact: {
        width: 10,
        height: 10,
    }
});

class Header extends Component {
    render() {
        return (
            <View style={[styles.cover, { backgroundColor: this.props.background_color }]}>
                <View wrap style={styles.section}>
                    <Image
                        style={styles.image}
                        src={this.props.image || 'https://cdn.iconscout.com/icon/free/png-256/avatar-373-456325.png'}
                    />
                    <View wrap style={styles.personal}>
                        <Text wrap style={styles.name}>{this.props.name || 'María Blanco'}</Text>
                        <Text wrap style={styles.job}>{this.props.job || 'Diseñadora UI/UX'}</Text>
                        <Text wrap style={styles.contact}>
                            <Image
                                style={styles.imageContact}
                                src={marker}
                            />
                            <Text > {this.props.city || ' Madrid'} {this.props.street || ' calle Casto, 32/a '} </Text>
                            <Image
                                style={styles.imageContact}
                                src={mobile}
                            />
                            <Text > {this.props.mobile || ' 00000000 '} </Text>
                            <Image
                                style={styles.imageContact}
                                src={email}
                            />
                            <Text > {this.props.email || ' example@dominio.es'}</Text>
                        </Text>
                    </View>
                </View>
                <Text style={styles.description}>{this.props.description || 'Comencé a estudiar Diseño Gráfico por vacación, así que decidí formalizarlo accediendo a la Diplomatura'+
                'en Diseño Gráfico en artediez, Madrid, recibiendo la titulación oficial en la Escuela Superior de Diseño de Madrid.\n Mis principales cualidades son mi carácter'+
                ' autodidacta e inquieto, implicada al 100% en el aprendizaje e investigación de nuevo conocimientos ralacionados con mi actividad profesional, así como el'+
                'desarrollo de proyectos personales tanto a nivel individual como colectivo, siempre con el objectivo de disfrutar, aprender y mejorar tanto personal como profesionalmente.'}</Text>
            </View>
        )
    }
}

export default Header;