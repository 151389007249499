import React, { Component } from 'react';
import { Form, Button, Accordion, Card } from 'react-bootstrap';
import { Row, Col, ButtonToolbar, OverlayTrigger, Popover, Toast } from 'react-bootstrap';
import text from '../../text.json';
import ButtonColor from './ButtonColor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';


const personal_data = text.datos_personales;

// Create Document Component
class PersonalDataForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            styleCheck: {
                fontSize: 12
            },
            showToast: false
        }

        this.handleImage = this.handleImage.bind(this);
        this.handleHeaderChange = this.handleHeaderChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleFromColor = (formModel) => {
        this.setState({ ...formModel },
            () => {
                //async sending state
                this.props.handlePersonalData(this.state);
            })
    }

    handleImage(event) {
        this.setState({ imageURL: URL.createObjectURL(event.target.files[0]) });
    }

    handleHeaderChange(value, property) {
        this.setState({ [property]: value },
            () => {
                //async sending state
                //this.props.handlePersonalData(this.state);
            })

    }

    handleSubmit(event) {
        //Validation
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({ validated: true });
        event.preventDefault();

        if (form.checkValidity()) {
            this.props.handlePersonalData(this.state);
            const fd = new FormData();
            fd.append('userName', this.refs.userName.value);
            fd.append('userJob', this.refs.userJob.value);
            fd.append('userEmail', this.refs.userEmail.value);
            fd.append('userCity', this.refs.userCity.value);
            fd.append('userTelephone', this.refs.userTelephone.value);
            fd.append('userAddress', this.refs.userAddress.value);
            var headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*"
            }
            axios.post('https://app.cvtrends.com/core_php.php', fd, headers
            ).then(res => {
                this.setState({
                    showToast: true
                }, () => {
                    setTimeout(() =>
                        this.setState({ showToast: false })
                        , 2000)
                })
            });
        }
    }

    render() {
        return (
            <Card id="scroll_experience">
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0" id="personal_data">
                                {personal_data.title}
                            </Accordion.Toggle>
                        </Col>
                        <Col xs={{ span: 2, offset: 4 }}>
                            <ButtonToolbar className="btn_info">
                                <OverlayTrigger
                                    trigger="click"
                                    key="left"
                                    placement="left"
                                    overlay={
                                        <Popover
                                            id={`popover-positioned-left`}
                                        >
                                            {personal_data.info}
                                        </Popover>
                                    }
                                >
                                    <FontAwesomeIcon icon={faInfo} className="ico_info" />
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Form.Group>
                                <Form.Control style={{ display: 'none' }} type="file" onChange={this.handleImage} ref={fileInput => this.fileInput = fileInput}></Form.Control>
                                <Row>
                                    <Col xs={5}>
                                        <Button onClick={() => this.fileInput.click()}>{personal_data.btn_image}</Button>
                                    </Col>
                                    <Col xs={{ span: 5, offset: 2 }}>
                                        <ButtonColor
                                            handleDataColor={this.handleFromColor}
                                            name={personal_data.btn_color} />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control required value={this.state.name || ''} onChange={(event) => this.handleHeaderChange(event.target.value, 'name')} type="text" placeholder={personal_data.ph_name} ref="userName" />
                                <Form.Control.Feedback type="invalid">{personal_data.val_name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control required value={this.state.job || ''} onChange={(event) => this.handleHeaderChange(event.target.value, 'job')} type="text" placeholder={personal_data.ph_job} ref="userJob" />
                                <Form.Control.Feedback type="invalid">{personal_data.val_job}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control required value={this.state.email || ''} onChange={(event) => this.handleHeaderChange(event.target.value, 'email')} type="email" placeholder={personal_data.ph_email} ref="userEmail" />
                                <Form.Control.Feedback type="invalid">{personal_data.val_email}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Control required value={this.state.city || ''} onChange={(event) => this.handleHeaderChange(event.target.value, 'city')} placeholder={personal_data.ph_city} type="text" ref="userCity" />
                                        <Form.Control.Feedback type="invalid">{personal_data.val_city}</Form.Control.Feedback>
                                    </Col>
                                    <Col>
                                        <Form.Control required value={this.state.mobile || ''} onChange={(event) => this.handleHeaderChange(event.target.value, 'mobile')} placeholder={personal_data.ph_tel} type="number" ref="userTelephone" />
                                        <Form.Control.Feedback type="invalid">{personal_data.val_tel}</Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control required value={this.state.street || ''} onChange={(event) => this.handleHeaderChange(event.target.value, 'street')} placeholder={personal_data.ph_address} ref="userAddress" />
                                <Form.Control.Feedback type="invalid">{personal_data.val_address}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control required value={this.state.description || ''} onChange={(event) => this.handleHeaderChange(event.target.value, 'description')} as="textarea" rows="3" placeholder={personal_data.ph_desc} />
                                <Form.Control.Feedback type="invalid">{personal_data.val_desc}</Form.Control.Feedback>
                            </Form.Group>
                            <p style={{ fontSize: 10, textAlign: "justify" }}>SUMMON PRESS, S.L. es el responsable de la recogida y tratamiento de sus datos con la finalidad de gestionar su consulta.
                                La base jurídica para este tratamiento reside en su consentimiento. No está previsto ceder sus datos a terceros.
                                Le recordamos que tiene el derecho a acceder, rectificar y suprimir sus datos personales, así como otros derechos, como se explica en la
                            <a href="https://cvtrends.com/politica-privacidad"> Política de privacidad</a></p>
                            <Form.Group>
                                <Form.Check
                                    required
                                    style={this.state.styleCheck}
                                    label="Consiento el tratamiento de mis datos para crear mi curriculum"
                                    feedback="Acepta nuestras Condiciones del servicio y Política de privacidad"
                                />
                            </Form.Group>
                            <Button style={{ margin: '20px auto', width: '100%' }} variant="warning" type="submit" /*onClick={this.handleSubmit}*/>
                                {personal_data.btn_update}
                            </Button>
                        </Form>
                        <Toast show={this.state.showToast}><Toast.Body>¡Tus datos se han guardado correctamente!</Toast.Body></Toast>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }
}

export default PersonalDataForm;