/* eslint-disable import/first */
import React, { Component } from 'react';
import { Form, Button, Accordion, Card } from 'react-bootstrap';
import { Row, Col, ButtonToolbar, OverlayTrigger, Popover, Toast } from 'react-bootstrap';
import text from '../../text.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es);

import "react-datepicker/dist/react-datepicker.css";


const studies = text.estudios;

// Create Document Component
class StudyDataForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            num_of_studies: 0,
            validated: false,
            showToast: false
        }

        this.handleHeaderChange = this.handleHeaderChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    handleHeaderChange(value, property) {
        this.setState({ [property]: value },
            () => {
                //async sending state
                //this.props.handleStudyData(this.state);
            })
    }

    handleSubmit(event) {
        //Validation
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            this.props.handleStudyData(this.state);
            this.setState({
                showToast: true
            }, () => {
                setTimeout(() =>
                    this.setState({ showToast: false })
                    , 2000)
            })
        }

        this.setState({ validated: true });
        event.preventDefault();
    }

    addItem(property) {
        this.setState({ [property]: this.state[property] + 1 },
            () => { });
    }

    removeItem(index, a_items, a_card, property) {
        this.setState({ [property]: this.state[property] - 1 },
            () => {
                a_items.forEach(element => {
                    this.setState({
                        [element + index]: this.state[element + (index + 1)],
                        [element + (index + 1)]: ''
                    });
                });
                a_card.splice((index - 1), 1);
            });
    }

    showStudies(num) {
        let cardStudy = [];
        const itemDesc = ['st_career_', 'st_city_', 'st_college_', 'st_date_start_', 'st_date_end_', 'st_description_'];
        for (let i = 1; i <= num; i++) {
            cardStudy.push(
                <Accordion key={i + "_st"} defaultActiveKey={1}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                                {studies.card_btn_open}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={i}>
                            <Card.Body>
                                <Form.Group>
                                    <Row>
                                        <Col md={12} lg={6}>
                                            <Form.Label>{studies.card_date_start}</Form.Label>
                                            {/*<Form.Control required value={this.state['st_date_start_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('st_date_start_' + i).toString())} type="date" />*/}
                                            <DatePicker
                                                selected={this.state['st_date_start_' + i] || ''}
                                                onChange={(event) => this.handleHeaderChange(event, ('st_date_start_' + i).toString())}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={<Form.Control />}
                                                placeholderText="dd/mm/aaaa"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                withPortal
                                                isClearable={true}
                                                maxDate={new Date()}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">{studies.card_val_date_start}</Form.Control.Feedback>
                                        </Col>
                                        <Col md={12} lg={6}>
                                            <Form.Label>{studies.card_date_end}</Form.Label>
                                            {/*<Form.Control value={this.state['st_date_end_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('st_date_end_' + i).toString())} type="date" />*/}
                                            <DatePicker
                                                selected={this.state['st_date_end_' + i] || ''}
                                                onChange={(event) => this.handleHeaderChange(event, ('st_date_end_' + i).toString())}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={<Form.Control />}
                                                placeholderText="dd/mm/aaaa"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                withPortal
                                                maxDate={new Date()}
                                                isClearable={true}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row>
                                        <Col>
                                            <Form.Control required value={this.state['st_career_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('st_career_' + i).toString())} placeholder={studies.card_ph_career} />
                                            <Form.Control.Feedback type="invalid">{studies.card_val_career}</Form.Control.Feedback>
                                        </Col>
                                        <Col>
                                            <Form.Control required value={this.state['st_city_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('st_city_' + i).toString())} placeholder={studies.card_ph_city} />
                                            <Form.Control.Feedback type="invalid">{studies.card_val_city}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control required value={this.state['st_college_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('st_college_' + i).toString())} placeholder={studies.card_ph_college} />
                                    <Form.Control.Feedback type="invalid">{studies.card_val_college}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control required value={this.state['st_description_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('st_description_' + i).toString())} as="textarea" rows="3" placeholder={studies.card_ph_desc} />
                                    <Form.Control.Feedback type="invalid">{studies.card_val_desc}</Form.Control.Feedback>
                                </Form.Group>
                                <Toast show={this.state.showToast}><Toast.Body>¡Tus datos se han guardado correctamente!</Toast.Body></Toast>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Button className="btn_remove" variant="link" onClick={() => this.removeItem(i, itemDesc, cardStudy, 'num_of_studies')}>{studies.card_btn_remove}</Button>
                    </Card>
                </Accordion>
            );
        }
        return cardStudy;
    }

    render() {
        return (
            <Card id="scroll_languages">
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            <Accordion.Toggle as={Button} variant="link" eventKey="3" id="studies"
                                onClick={() => document.getElementById("scroll_study").scrollIntoView(true)}>
                                {studies.title}
                            </Accordion.Toggle>
                        </Col>
                        <Col xs={{ span: 2, offset: 4 }}>
                            <ButtonToolbar className="btn_info">
                                <OverlayTrigger
                                    trigger="click"
                                    key="left"
                                    placement="left"
                                    overlay={
                                        <Popover
                                            id={`popover-positioned-left`}
                                        >
                                            {studies.info}
                                        </Popover>
                                    }
                                >
                                    <FontAwesomeIcon icon={faInfo} className="ico_info" />
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Accordion defaultActiveKey="0">
                                {this.showStudies(this.state.num_of_studies)}
                                <Button className="btn_add_more" variant="secondary" onClick={() => this.addItem('num_of_studies')}>{studies.btn_add}</Button>
                            </Accordion>
                            <Button style={{ margin: '20px auto', width: '100%' }} variant="warning" type="submit">
                                {studies.btn_update}
                            </Button>
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }

}

export default StudyDataForm;