import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

class mainHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isTop: true,
        }
    }

    componentDidMount() {
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 100;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop })
            }
        });
    }

    render() {
        const isMobile = window.innerWidth <= 991;
        const style = {
            link: {
                fontWeight: 700,
                fontSize: 14,
                color: isMobile ? 'rgba(61, 61, 61, 0.69)' : this.state.isTop ? 'white' : 'rgba(61, 61, 61, 0.69)'
            },
            navBar: {
                borderBottom: isMobile ? '1px solid rgba(0,0,0,.08)' : this.state.isTop ? '1px solid rgba(255,255,255,.1)' : '1px solid rgba(0,0,0,.08)',
                padding: '15px 30px',
                height: 'auto'
            }

        }
        return (
            <Navbar
                sticky="top"
                collapseOnSelect expand="lg"
                bg-transparent={isMobile ? 'false' : this.state.isTop ? 'true' : 'false'}
                bg={isMobile ? 'light' : this.state.isTop ? '' : 'light'}
                variant={isMobile ? 'light' : this.state.isTop ? 'dark' : 'light'}
                style={style.navBar}
            >
                <Navbar.Brand href="https://cvtrends.com/">
                    <img
                        src={isMobile ? 'https://cvtrends.com/wp-content/uploads/2018/08/cvt_logo_black.png' : this.state.isTop ? 'https://cvtrends.com/wp-content/uploads/2018/08/cvt_logo_white.png' : 'https://cvtrends.com/wp-content/uploads/2018/08/cvt_logo_black.png'}
                        alt="cvtrend-logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav>
                        <Nav.Link href="https://cvtrends.com/" style={style.link}>{"CURRÍCULUM " + new Date().getFullYear()}</Nav.Link>
                        <Nav.Link href="https://cvtrends.com/cv-basico" style={style.link}>CURRÍCULUM BÁSICO</Nav.Link>
                        <Nav.Link href="https://cvtrends.com/plantillas" style={style.link}>PLANTILLAS CV</Nav.Link>
                        <Nav.Link href="https://cvtrends.com/blog" style={style.link}>BLOG</Nav.Link>
                        <Nav.Link href="https://cvtrends.com/contacto" style={style.link}>CONTACTO</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default mainHeader;