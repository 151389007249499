import React, { useState, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import { Button } from 'react-bootstrap'

class ButtonColor extends React.Component {
    state = {
        displayColorPicker: false,
    };

    //color
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex }, () => {
            this.props.handleDataColor(this.state);
        });
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        return (
            <span>
                <Button
                    onClick={this.handleClick}
                    style={{ position: 'absolute', right: 20 }}>
                    {this.props.name}
                </Button>
                {this.state.displayColorPicker ? <div style={popover}>
                    <div style={cover} onClick={this.handleClose} />
                    <SketchPicker
                        color={this.state.background || ''}
                        onChangeComplete={this.handleChangeComplete} />
                </div> : null}
            </span>
        )
    }
}

export default ButtonColor

/*const ButtonColor = props => {

    const [displayColorPicker, setDisplay] = useState(false);
    const [background, setColor] = useState(null);

    //color
    const handleChangeComplete = (color) => {
        setColor(color.hex);         
    };

    //when your function is mounted, run useEffect()
    useEffect(() => {        
        console.log(props);
    });

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
    
    return (
        <span>
            <Button
                onClick={()=>setDisplay(!displayColorPicker)}
                style={{ position: 'absolute', right: 20 }}>
                {props.name}
            </Button>
            {displayColorPicker ? <div style={popover}>
                <div style={cover} onClick={()=>setDisplay(false)} />
                <SketchPicker
                    color={background || ''}
                    onChangeComplete={handleChangeComplete} 
                />
            </div> : null}
        </span>
    )    
}

export default ButtonColor*/

