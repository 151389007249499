import React, { Component } from 'react';
import { Form, Button, Accordion, Card } from 'react-bootstrap';
import { Row, Col, ButtonToolbar, OverlayTrigger, Popover, Toast } from 'react-bootstrap';
import text from '../../text.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

const languages = text.idiomas;

// Create Document Component
class LanguageDataForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            num_of_languages: 0,
            validated: false,
            showToast: false
        }

        this.handleHeaderChange = this.handleHeaderChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    handleHeaderChange(value, property) {
        this.setState({ [property]: value },
            () => {
                //async sending state
                //this.props.handleLanguageData(this.state);
            })
    }

    handleSubmit(event) {
        //Validation
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        this.setState({ validated: true });

        if (form.checkValidity()) {
            this.props.handleLanguageData(this.state);
            this.setState({
                showToast: true
            }, () => {
                setTimeout(() =>
                    this.setState({ showToast: false })
                    , 2000)
            })
        }
        event.preventDefault();
    }

    addItem(property) {
        this.setState({ [property]: this.state[property] + 1 },
            () => { });

    }

    removeItem(index, a_items, a_card, property) {
        this.setState({ [property]: this.state[property] - 1 },
            () => {
                a_items.forEach(element => {
                    this.setState({
                        [element + index]: this.state[element + (index + 1)],
                        [element + (index + 1)]: ''
                    });
                });
                a_card.splice((index - 1), 1);
            });
    }

    showLanguages(num) {
        let cardLanguage = [];
        const itemDesc = ['lan_nation_', 'lan_level_'];
        const selcetLang = languages.card_select_level;

        for (let i = 1; i <= num; i++) {
            cardLanguage.push(
                <Accordion key={i + "_ln"} defaultActiveKey={1}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                                {languages.card_btn_open}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={i}>
                            <Card.Body>
                                <Form.Group>
                                    <Row>
                                        <Col>
                                            <Form.Control required value={this.state['lan_nation_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('lan_nation_' + i).toString())} placeholder={languages.card_ph_lang} />
                                            <Form.Control.Feedback type="invalid">{languages.card_val_lang}</Form.Control.Feedback>
                                        </Col>
                                        <Col>
                                            <Form.Control required value={this.state['lan_level_' + i] || ''} as="select" onChange={(event) => this.handleHeaderChange(event.target.value, ('lan_level_' + i).toString())}>
                                                {
                                                    selcetLang.map((select) => {
                                                        return (<option key={"selectLang_" + select}>{select}</option>)
                                                    })
                                                }
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{languages.card_val_level}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Toast show={this.state.showToast}><Toast.Body>¡Tus datos se han guardado correctamente!</Toast.Body></Toast>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Button className="btn_remove" variant="link" onClick={() => this.removeItem(i, itemDesc, cardLanguage, 'num_of_languages')}>{languages.card_btn_remove}</Button>
                    </Card>
                </Accordion>
            );
        }
        return cardLanguage;
    }

    render() {
        return (
            <Card id="scroll_info">
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            <Accordion.Toggle as={Button} variant="link" eventKey="4" id="languages"
                                onClick={() => document.getElementById("scroll_languages").scrollIntoView(true)}>
                                {languages.title}
                            </Accordion.Toggle>
                        </Col>
                        <Col xs={{ span: 2, offset: 4 }}>
                            <ButtonToolbar className="btn_info">
                                <OverlayTrigger
                                    trigger="click"
                                    key="left"
                                    placement="left"
                                    overlay={
                                        <Popover
                                            id={`popover-positioned-left`}
                                        >
                                            {languages.info}
                                        </Popover>
                                    }
                                >
                                    <FontAwesomeIcon icon={faInfo} className="ico_info" />
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Accordion defaultActiveKey="0">
                                {this.showLanguages(this.state.num_of_languages)}
                                <Button className="btn_add_more" variant="secondary" onClick={() => this.addItem('num_of_languages')}>{languages.btn_add}</Button>
                            </Accordion>
                            <Button style={{ margin: '20px auto', width: '100%' }} variant="warning" type="submit">
                                {languages.btn_update}
                            </Button>
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }

}

export default LanguageDataForm;