/* eslint-disable import/first */
import React, { Component } from 'react';
import { Form, Button, Accordion, Card } from 'react-bootstrap';
import { Row, Col, ButtonToolbar, OverlayTrigger, Popover, Toast } from 'react-bootstrap';
import text from '../../text.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es);

import "react-datepicker/dist/react-datepicker.css";


const info_data_text = text.informaciones_adicionales;
// Create Document Component
class InfoDataForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            num_of_info: 0,
            validated: false,
            showToast: false
        }

        this.handleHeaderChange = this.handleHeaderChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    handleHeaderChange(value, property) {
        this.setState({ [property]: value },
            () => {
                //async sending state
                //this.props.handleInfoData(this.state);
            })
    }

    handleSubmit(event) {
        //Validation
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        this.setState({ validated: true });
        //send datas
        if (form.checkValidity()) {
            this.props.handleInfoData(this.state);
            this.setState({
                showToast: true
            }, () => {
                setTimeout(() =>
                    this.setState({ showToast: false })
                    , 2000)
            })
        }
        event.preventDefault();
    }

    addItem(property) {
        this.setState({ [property]: this.state[property] + 1 },
            () => { });

    }

    removeItem(index, a_items, a_card, property) {
        this.setState({ [property]: this.state[property] - 1 },
            () => {
                a_items.forEach(element => {
                    this.setState({
                        [element + index]: this.state[element + (index + 1)],
                        [element + (index + 1)]: ''
                    });
                });
                a_card.splice((index - 1), 1);
            });
    }

    showInfo(num) {
        let cardInfo = [];
        const itemDesc = ['info_title_', 'info_start_', 'info_end_', 'info_description_'];
        for (let i = 1; i <= num; i++) {
            cardInfo.push(
                <Accordion key={i + "info"} defaultActiveKey={1}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                                {info_data_text.card_btn_open}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={i}>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label>{info_data_text.card_info_title}</Form.Label>
                                    <Form.Control value={this.state['info_title_' + i]} as="select" onChange={(event) => this.handleHeaderChange(event.target.value, ('info_title_' + i).toString())}>
                                        <option>Aficiones</option>
                                        <option>Disponibilidad</option>
                                        <option>Habilidades</option>
                                        <option>Reconocimientos</option>
                                        <option>Publicaciones</option>
                                        <option>Labor social</option>
                                    </Form.Control>
                                </Form.Group>
                                {((this.state['info_title_' + i] === 'Habilidades') || (this.state['info_title_' + i] === 'Aficiones') || (typeof this.state['info_title_' + i] === 'undefined')) ? ''
                                    :
                                    <Form.Group>
                                        <Row>
                                            <Col md={12} lg={6}>
                                                <Form.Label>{info_data_text.card_date_start}</Form.Label>
                                                {/*<Form.Control required value={this.state['info_start_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('info_start_' + i).toString())} type="date" />*/}
                                                <DatePicker
                                                    selected={this.state['info_start_' + i] || ''}
                                                    onChange={(event) => this.handleHeaderChange(event, ('info_start_' + i).toString())}
                                                    dateFormat="dd/MM/yyyy"
                                                    locale="es"
                                                    customInput={<Form.Control />}
                                                    placeholderText="dd/mm/aaaa"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    withPortal
                                                    isClearable={true}
                                                    maxDate={new Date()}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">{info_data_text.card_val_date_start}</Form.Control.Feedback>
                                            </Col>
                                            {(this.state['info_title_' + i] === 'Labor social') ?
                                                <Col md={12} lg={6}>
                                                    <Form.Label>{info_data_text.card_date_end}</Form.Label>
                                                    {/*Form.Control value={this.state['info_end_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('info_end_' + i).toString())} type="date" />*/}
                                                    <DatePicker
                                                        selected={this.state['info_end_' + i] || ''}
                                                        onChange={(event) => this.handleHeaderChange(event, ('info_end_' + i).toString())}
                                                        dateFormat="dd/MM/yyyy"
                                                        locale="es"
                                                        customInput={<Form.Control />}
                                                        placeholderText="dd/mm/aaaa"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        maxDate={new Date()}
                                                        dropdownMode="select"
                                                        withPortal
                                                        isClearable={true}
                                                    />
                                                </Col>
                                                : ''
                                            }
                                        </Row>
                                    </Form.Group>
                                }
                                <Form.Group>
                                    <Form.Control required value={this.state['info_description_' + i] || ''} onChange={(event) => this.handleHeaderChange(event.target.value, ('info_description_' + i).toString())} as="textarea" rows="3" placeholder={info_data_text.ph_desc} />
                                    <Form.Control.Feedback type="invalid">{info_data_text.card_val_desc}</Form.Control.Feedback>
                                </Form.Group>
                                <Toast show={this.state.showToast}><Toast.Body>¡Tus datos se han guardado correctamente!</Toast.Body></Toast>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Button className="btn_remove" variant="link" onClick={() => this.removeItem(i, itemDesc, cardInfo, 'num_of_info')}>{info_data_text.card_btn_remove}</Button>
                    </Card>
                </Accordion>
            );
        }
        return cardInfo;
    }

    render() {

        return (
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            <Accordion.Toggle as={Button} variant="link" eventKey="5" id="info_data"
                                onClick={() => document.getElementById("scroll_info").scrollIntoView(true)}>
                                {info_data_text.title}
                            </Accordion.Toggle>
                        </Col>
                        <Col xs={{ span: 2, offset: 4 }}>
                            <ButtonToolbar className="btn_info">
                                <OverlayTrigger
                                    trigger="click"
                                    key="left"
                                    placement="left"
                                    overlay={
                                        <Popover
                                            id={`popover-positioned-left`}
                                        >
                                            {info_data_text.info_desc}
                                        </Popover>
                                    }
                                >
                                    <FontAwesomeIcon icon={faInfo} className="ico_info" />
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Accordion defaultActiveKey="0">
                                {this.showInfo(this.state.num_of_info)}
                                <Button className="btn_add_more" variant="secondary" onClick={() => this.addItem('num_of_info')}>{info_data_text.btn_add}</Button>
                            </Accordion>
                            <Button style={{ margin: '20px auto', width: '100%' }} variant="warning" type="submit">
                                {info_data_text.btn_update}
                            </Button>
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }

}

export default InfoDataForm;