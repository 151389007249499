import React from 'react';
import ReactDOM from 'react-dom';
import Header from './components/pdf/Header';
import Experience from './components/pdf/Experience'
import Studies from './components/pdf/Studies';
import Languages from './components/pdf/Languages';
import Form from './FormPDF';
import MainHeader from './components/mainPage/mainHeader'
import MainFooter from './components/mainPage/mainFooter'
import Informations from './components/pdf/Informations';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { Document, Page, StyleSheet, Font, BlobProvider } from '@react-pdf/renderer';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './custom-bootstrap.css';
import text from './text.json';
import { isMobile } from 'react-device-detect';

Font.register({ family: 'Lato', fonts: [
  { src: 'https://fonts.gstatic.com/s/lato/v11/h7rISIcQapZBpei-sXwIwg.ttf' }, // font-style: normal, font-weight: normal
  { src: 'https://fonts.gstatic.com/s/lato/v11/P_dJOFJylV3A870UIOtr0w.ttf', fontStyle: 'italic' },
  { src: 'https://fonts.gstatic.com/s/lato/v11/WFcZakHrrCKeUJxHA4T_gw.ttf', fontStyle: 'italic', fontWeight: 700 },
  { src: 'https://fonts.gstatic.com/s/lato/v11/iX_QxBBZLhNj5JHlTzHQzg.ttf', fontWeight: 700 },
 ]});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 60,
    fontFamily: 'Lato'
  }
});

const home = text.home;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleParentData = (formModel) => {
    this.setState({ ...formModel });
  }

  render() {

    return (
      <>
        <MainHeader />
        <div className="bgImage">
          <div className="boxTitle">
            <p className="mainText">{home.title}</p>
            <p className="mainSubText">{home.sub_title}</p>
          </div>
        </div>
        <div className="boxCV">
          <BlobProvider document={
            <Document title={"resume " + this.state.name}>
              <Page style={styles.page}>
                <Header
                  name={this.state.name}
                  job={this.state.job}
                  city={this.state.city}
                  mobile={this.state.mobile}
                  street={this.state.street}
                  description={this.state.description}
                  image={this.state.imageURL}
                  email={this.state.email}
                  background_color={this.state.background}
                />
                <Experience
                  handleDataState={this.state}
                />
                <Studies
                  handleDataState={this.state} />
                <Languages
                  handleDataState={this.state} />
                <Informations
                  handleDataState={this.state} />
              </Page>
            </Document>
          }>
            {({ blob, url, loading, error }) => {
              // Do whatever you need with blob here
              return (
                <div>
                  <Row>
                    {isMobile ? <Col md={12}><Form handleData={this.handleParentData} /></Col> : <Col md={6}><Form handleData={this.handleParentData} /></Col>}
                    {isMobile ? <Col md={12} style={{ marginTop: 10 }}><iframe src={"/web/viewer.html?file=" + url} width="100%" height="450px" title={"CurriulumMobile"}></iframe></Col> : <Col md={6} className="desktop"><iframe width="100%" height="100%" src={"/web/viewer.html?file=" + url} title={"Curriculum"}></iframe></Col>}

                  </Row>
                  <Row style={{ marginTop: 10 }}>
                    <Col md={{ span: 8, offset: 2 }}>
                      <Alert variant="warning">* Rellena tus datos personales y pulsa <strong>Actualiza Plantilla</strong> para poder descargar tu CV</Alert>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ span: 8, offset: 2 }} style={{textAlign: 'center'}}>
                      {this.state.name ?
                        <Button
                          className="resume_download"
                          id="download-cv"
                          variant="outline-warning"                          
                          href={url}
                          download={"curriculum_" + this.state.name + ".pdf"}>
                          {loading ? 'Cargando documento...' : home.btn_download}
                        </Button>
                        :
                        <Button
                        variant="outline-secondary"
                        disabled
                        >
                          {home.btn_download}
                        </Button>}
                    </Col>
                  </Row>
                </div>
              )
            }}
          </BlobProvider>
        </div>
        <MainFooter />
      </>
    )
  }
}

ReactDOM.render(<Index />, document.getElementById('root'));

